<template>
  <div v-cloak>
    <transition appear appear-active-class="fade-enter-active" name="fade">
      <div v-if="apiLoaded || errorText">
        <div v-if="apiLoaded" class="wrap">
          <template v-if="id == null">
            <h1>Создание конфигурации комплексной модернизации</h1>
          </template>
          <template v-else>
            <h1>Редактирование<br> конфигурации id {{ config.id }}</h1>
            <div class="creation-date">
              Дата создания<span>{{ config.createdDate | dateFormat }}</span>
            </div>
            <div class="creation-date">
              Дата изменения<span v-if="config.modifiedDate">{{ config.modifiedDate | dateFormat }}</span>
              <span v-else>—</span>
            </div>
          </template>

          <h4>Наименование</h4>
          <v-text-field label="Наименование" v-model="config.title"></v-text-field>

          <h4 class="mt-2">Сокращённое наименование</h4>
          <v-text-field label="Сокращённое наименование" v-model="config.configParams.shortTitle"></v-text-field>

          <h4 class="mt-2">Описание</h4>
          <v-text-field label="Описание" v-model="config.configParams.description"></v-text-field>

          <h4 class="mt-2 mb-2">Файлы</h4>
          <div v-if="config.configParams.staticFiles">
            <v-row class="file-row align-center" v-for="(staticFilePath,index) in config.configParams.staticFiles" :key="'common2'+index+staticFileKey">
              <v-col cols="12" lg="4">
                <a :href="staticFilePath" download class="file-link">
                  <v-icon>mdi-file-document-outline</v-icon>
                  {{ truncateStr(staticFilePath.substring(staticFilePath.lastIndexOf("/")+1), 50) }}
                </a>
              </v-col>
<!--              <v-col cols="12" lg="5" class="d-flex align-center">-->
<!--                <div class="delete ml-2 file-delete"-->
<!--                     @click="deleteFile(staticFilePath)">-->
<!--                  <v-icon color="#0033A0">mdi-delete-outline</v-icon>-->
<!--                </div>-->
<!--              </v-col>-->
            </v-row>
          </div>
          <div v-for="(file, index) in attachedFiles" :key="index+'fileIndex'" class="file-input">
            <v-file-input v-model="attachedFiles[index]" show-size dense outlined truncate-length="42" :rules="inputRules"></v-file-input>
          </div>
          <v-btn @click="attachedFiles.push('')" outlined style="height: 25px; margin-left: 33px">+ Добавить поле</v-btn>


          <h4 class="mt-8">Параметры мониторинга</h4>
          <template v-if="id == null">
            <v-btn outlined @click="$refs['fileInput'].click()" class="mt-4">загрузить файл конфигурации</v-btn>
            <input ref="fileInput" style="display: none" type="file" @change="loadJson">
          </template>

<!--          {{config}}-->
          <div class="list config">
            <div v-for="(section, sectionIndex) in config.configParams.sections" :key="section.id" class="list-item-wrap">
              <v-expansion-panels>
                <v-expansion-panel :key="'section'+section.id">
                  <v-expansion-panel-header inside @keyup.space.prevent expand-icon="">
                    <div class="list-item">
                      <div class="list-item-name">
                        <button class="toggleSwitch">
                          <svg class="closed" fill="none" height="24" viewBox="0 0 24 24" width="24"
                               xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
                          </svg>
                          <svg class="opened" fill="none" height="24" viewBox="0 0 24 24" width="24"
                               xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
                          </svg>
                        </button>
                        <span>
                          <h4 v-if="sectionIndex === 0">Раздел</h4>
                          <q>{{ section.id / 100 }}.</q>
                          <v-text-field v-model="section.name" @click.stop="" label="Наименование раздела"></v-text-field>
                        </span>
                        <div v-if="config.configParams.sections.length > 1"
                             class="delete"
                             @click.stop="deleteSection(config.configParams.sections, section.id)">
                          <v-icon color="#0033A0">mdi-delete-outline</v-icon>
                        </div>
                      </div>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-expansion-panels class="justify-start">
                      <v-expansion-panel v-for="(index, indexIndex) in section.indices"
                                         :key="'index'+section.id+index.id" class="level2">
                        <div class="list-item-wrap">
                          <v-expansion-panel-header @keyup.space.prevent expand-icon="">
                            <div class="list-item">
                              <div class="list-item-name">
                                <button class="toggleSwitch whiteBg">
                                  <svg class="closed" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
                                  </svg>
                                  <svg class="opened" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
                                  </svg>
                                </button>
                                <span>
                                  <h4 v-if="indexIndex === 0">Показатель</h4>
                                  <q>{{ section.id / 100 }}.{{ index.id / 100 }}</q>
                                  <v-text-field v-model="index.name" @click.stop="" label="Наименование показателя"></v-text-field>
                                </span>
                                <div v-if="section.indices.length > 1"
                                     class="delete"
                                     @click.stop="deleteIndex(config.configParams.sections, section.id, index.id)">
                                  <v-icon color="#0033A0">mdi-delete-outline</v-icon>
                                </div>
                              </div>
                            </div>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content class="level3">
                            <div v-for="(parameter, parameterIndex) in index.parameters"
                                 :key="'parameter'+section.id+index.id+parameter.id+parameterKey" class="list-item">
                              <div class="list-item-name">
                                <span>
                                  <h4 v-if="parameterIndex === 0">Параметр</h4>
                                  <q>{{ section.id / 100 }}.{{ index.id / 100 }}.{{ parameter.id / 100 }}</q>
                                  <v-text-field v-model="parameter.name" label="Наименование параметра"></v-text-field>
                                </span>
                                <div v-if="index.parameters.length > 1"
                                     class="delete"
                                     @click.stop="deleteParameter(config.configParams.sections, section.id, index.id, parameter.id)">
                                  <v-icon color="#0033A0">mdi-delete-outline</v-icon>
                                </div>
                              </div>
                              <div class="list-item-type">
                                <table>
                                  <tr>
                                    <td>
                                      <v-select
                                          label="Тип параметра"
                                          :items="parametersType"
                                          item-text="title"
                                          item-value="type"
                                          v-model="parameter.type"
                                      ></v-select>
                                    </td>
                                    <td>
                                      <v-checkbox label="Прикрепление файла обязательно" v-model="parameter.isFileNeeded"></v-checkbox>
                                    </td>
                                    <td style="width: 300px">
                                      <DateField
                                          labelName="Отчётная дата"
                                          :dateValue="parameter.recommendedEndDate"
                                          @minput="parameter.recommendedEndDate=$event; parameterKey+=1"
                                      ></DateField>
                                    </td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                            <a class="configAdd" style="margin-left: 0; margin-top: -24px!important;" href="#"
                               @click.prevent="addParameter(index)">+ Добавить параметр</a>
                          </v-expansion-panel-content>
                        </div>
                      </v-expansion-panel>
                      <a style="margin-left: 80px;" class="configAdd" href="#" @click.prevent="addIndex(section)">+ Добавить показатель</a>
                    </v-expansion-panels>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
            <a class="configAdd" href="#" @click.prevent="addSection(config.configParams)">+ Добавить раздел</a>
          </div>

          <h4 class="mb-2 mt-4">Уровень мониторинга:</h4>
          <v-radio-group v-model="config.configParams.type" row @change="$forceUpdate()">
            <v-radio label="Регионы" value="region"></v-radio>
            <v-radio label="ЦЗН" value="czn"></v-radio>
          </v-radio-group>

          <template v-if="config.configParams.type === 'region'">
            <h4 class="mb-2 mt-4">Регионы</h4>
            <div class="region-list-wrap">
              <div class="region-buttons">
                <v-btn color="primary" outlined @click="selectAllRegions">Выделить все</v-btn>
                <v-btn color="primary" outlined @click="config.configParams.regions = []">Снять выделения</v-btn>
              </div>
              <div class="region-list">
              <div v-for="(region, regionIndex) in regionList" :key="'regionIndex' + regionIndex">
                <v-checkbox
                    :label="region.name"
                    :value="region.code"
                    v-model="config.configParams.regions"
                ></v-checkbox>
              </div>
              </div>
            </div>
          </template>

          <template v-if="config.configParams.type === 'czn'">
            <h4 class="mb-2 mt-4">ЦЗН</h4>
            <div class="region-list-wrap">
              <div class="region-buttons">
                <v-btn color="primary" outlined @click="selectAllCZN()">Выделить все</v-btn>
                <v-btn color="primary" outlined @click="deselectAllCZN()">Снять выделение</v-btn>
              </div>
              <div class="region-list">
              <div v-for="(regionWithCZN, regionWithCZNIndex) in $cznDepartmentsByRegionList"
                   :key="regionWithCZNIndex+'indexRegion'" class="list-item-wrap">
                <v-expansion-panels :accordion="true" :flat="true">
                  <v-expansion-panel :key="regionWithCZN+'index'">
                    <div class="expansion-panel-checkbox">
                      <v-expansion-panel-header inside @keyup.space.prevent expand-icon="">
                        <div class="list-item list-item-name">
                          <button class="toggleSwitch whiteBg">
                            <svg class="closed" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
                            </svg>
                            <svg class="opened" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
                            </svg>
                          </button>
                          <div>{{ regionWithCZN.name }}</div>
                        </div>
                      </v-expansion-panel-header>
                      <v-checkbox
                          v-model="allCZNinRegionAreSelected[regionWithCZNIndex]"
                          @click="allCZNinRegionAreSelected[regionWithCZNIndex] = !allCZNinRegionAreSelected[regionWithCZNIndex];
                                        selectCZNinRegion(regionWithCZN, regionWithCZNIndex)"
                          style="margin-right: 7px; margin-top: 8px"
                      ></v-checkbox>
                    </div>

                    <v-expansion-panel-content class="level2">
                      <div v-for="(czn, cznIndex) in regionWithCZN.departments"
                           :key="'cznIndex'+cznIndex+regionWithCZNIndex"
                           class="list-item">
                        <v-checkbox
                            :label="czn.name"
                            :value="czn.name"
                            v-model="config.configParams.czns"
                            @change="checkCZNinRegion(regionWithCZN, regionWithCZNIndex)"
                        ></v-checkbox>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
              </div>
            </div>
          </template>

          <div class="save-buttons mt-24">
            <v-btn color="primary" outlined @click="$router.push('/config/standardMonitoring')">Отмена</v-btn>
            <v-btn :disabled="submitButtonDisabled" color="primary" @click="submit">Сохранить изменения</v-btn>
          </div>
        </div>
        <div class="error" style="position: fixed;bottom: 0" v-if="errorText!=null">
          Ошибка: {{errorText}}
        </div>
      </div>
      <div v-else style="height:1000px"></div>
    </transition>
  </div>
</template>

<script>
import api from "@/modules/api";
import DateField from "@/components/elements/DateField";

export default {
  name: "standardMonitoringEdit",
  components: {DateField},
  data() {
    return {
      id: null,
      apiLoaded: false,
      errorText: null,
      submitButtonDisabled: false,
      attachedFiles: [''],
      staticFileKey: 0,
      minDate: '2022-12-01',
      maxDate: '2030-12-31',
      parameterKey: 0,
      // dateMenu: null,
      config: {
        configParams: {
          staticFiles: [],
          type: "region",
          regions: [],
          czns: [],
          sections: [
            {id: 100,
             name: "",
             indices: [
               {id: 100,
                name: "",
                parameters: [
                  {id: 100,
                   name: "",
                   isFileNeeded: true,
                   recommendedEndDate: null,
                   type: "STRING"}
               ]}
            ]}
         ]}
      },
      parametersType: [
        {type: 'STRING', title: 'Текст', description: 'Текстовое поле'},
        {type: 'NUMBER', title: 'Число', description: 'Числовое значение'},
        {type: 'DATE', title: 'Дата', description: 'Дата'},
      ],
      inputRules: [value => !value || value.size < 50000000 || 'Размер файла должен быть менее 50 MB'],
      regionList: null,
      allCZNinRegionAreSelected: [],
    };
  },

  methods: {
    async uploadFiles(configId) {
      // console.log('this.attachedFile', this.attachedFiles)
      if (this.attachedFiles != null && this.attachedFiles.length !== 0) {
        for (let file of this.attachedFiles) {
          if (file !== '' && file != null && file.size > 50000000) { //52428800
            alert('Файл больше 50Мб. Загрузите, пожалуйста, файл с меньшим размером');
            return
          }
        }

        for (let i = 0; i < this.attachedFiles.length; i++) {
          let file = this.attachedFiles[i];
          // console.log("file"+i, file)
          if (file != null && file !== '' && file !== {}) {
            let formData = new FormData();
            formData.append("file", this.attachedFiles[i]);
            let req = await api.postFile("/monitoring/typical/config/addStaticFile", {configId: configId}, formData, "POST")
            if (req.ok) {
              console.log('req ok')
            } else {
              this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
            }
          }
        }
      }
    },

    //скачивает конфигурацию с бэка
    async loadConfig(id) {
      let req = await this.$getApi("/monitoring/typical/config/getConfig", {id: id})
      // console.log('req', req)
      if (req.ok) {
        // console.log('req.payload', req.payload)
        this.config = req.payload
        this.config.configParams = JSON.parse(this.config.configJson)
        this.apiLoaded = true
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
    },

    deleteSection(config, sectionId) {
      for (let i = 0; i < config.length; i++) {
        if (config[i].id === sectionId) {
          config.splice(i, 1)
          break
        }
      }
      this.ordinateNumbers()
      this.$forceUpdate()
    },

    deleteIndex(config, sectionId, indexId) {
      for (let section of config) {
        if (section.id === sectionId) {
          for (let i = 0; i < section.indices.length; i++) {
            if (section.indices[i].id === indexId) {
              section.indices.splice(i, 1)
              break;
            }
          }
          break
        }
      }
      this.ordinateNumbers()
      this.$forceUpdate()
    },

    deleteParameter(config, sectionId, indexId, parameterId) {
      for (let section of config) {
        if (section.id === sectionId) {
          for (let index of section.indices) {
            if (index.id === indexId) {
              for (let i = 0; i < index.parameters.length; i++) {
                if (index.parameters[i].id === parameterId) {
                  index.parameters.splice(i, 1)
                  break;
                }
              }
              break;
            }
          }
          break
        }
      }
      this.ordinateNumbers()
      this.$forceUpdate()
    },

    addSection(config) {
      if(config.sections == null){
        config.sections = []
      }
      config.sections.push({
        id: 100,
        name: "",
        indices: [{id: 100, name: "", parameters: [{id: 100, name: "", isFileNeeded: true, type: "STRING", recommendedEndDate: null}]}]
      })
      this.ordinateNumbers()
      this.$forceUpdate();
    },

    addIndex(section) {
      section.indices.push({id: 100, name: "", parameters: [{id: 100, name: "", isFileNeeded: true, type: "STRING", recommendedEndDate: null}]})
      this.ordinateNumbers()
      this.$forceUpdate();
    },

    addParameter(index) {
      index.parameters.push({id: 100, name: "", isFileNeeded: true, type: "STRING", recommendedEndDate: null})
      this.ordinateNumbers()
      this.$forceUpdate();
    },

    //после удаления какого-то элемента порядок номеров разделов-групп показателей-показателей собъется,
    //и нужно все снова расставить. При добавлении - аналогично
    ordinateNumbers() {
      for (let i = 0; i < this.config.configParams.sections.length; i++) {
        let section = this.config.configParams.sections[i]
        section.id = i * 100 + 100
        for (let j = 0; j < section.indices.length; j++) {
          let index = section.indices[j]
          index.id = j * 100 + 100
          for (let k = 0; k < index.parameters.length; k++) {
            let parameter = index.parameters[k]
            parameter.id = k * 100 + 100
          }
        }
      }
    },

    //кнопка "Сохнанить изменения"
    async submit() {
      let config = {},
          validStat = this.validateConfig(this.config);
      if (validStat === true) {
        config = this.formConfig();
        config.configJson = JSON.stringify(config.configJson);
        // console.log('config submit', config);
        if (this.id == null) {
          await this.saveConfig(config, "/monitoring/typical/config/saveConfig");
        } else {
          await this.saveConfig(config, "/monitoring/typical/config/updateConfig");
        }
      } else {
        this.errorText = validStat
      }
    },

    async saveConfig(config, path) {
      this.submitButtonDisabled = true
      let req = await this.$postApi(path, config)
      // console.log ('req', req)
      if (req.ok) {
        this.id = req.payload.id;
        await this.uploadFiles(this.id);
        this.$router.push("/config/standardMonitoring");
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
      this.submitButtonDisabled = false
    },

    validateConfig(config) {
      if (config.title == null || config.title.length < 1) {
        return "Должно быть указано наименование комплексной модернизации"
      }
      if (config.configParams.shortTitle == null || config.configParams.shortTitle.length < 1) {
        return "Должно быть указано сокращённое наименование комплексной модернизации"
      }
      // if (config.configParams.description == null || config.configParams.description.length < 1) {
      //   return "Должно быть указано описание комплексной модернизации"
      // }
      let validNom = this.validateSection(config.configParams.sections);
      if (!(validNom === true)) {
        return validNom
      }
      if (config.configParams.type === "region") {
        if (config.configParams.regions.length === 0) {
          return "Должен быть выбран хотя бы один регион"
        }
      } else if (config.configParams.type === "czn"){
        if (config.configParams.czns.length === 0) {
          return "Должен быть выбран хотя бы один ЦЗН"
        }
      }
      return true
    },

    validateSection(sections) {
      if (sections == null || sections.length < 1) {
        return "Должен быть хотя бы один раздел"
      }
      for (let i = 0; i < sections.length; i++) {
        let section = sections[i];
        if (section.name === "" || section.name.length === 0) {
          return "Должно быть указано наименование раздела " + (i + 1)
        }
        if (section.id !== i * 100 + 100) {
          return "Идентификаторы разделов должны быть кратны 100 и следовать по порядку (раздел "+ (i + 1) + ")"
        }
        for (let j = 0; j < section.indices.length; j++) {
          let index = section.indices[j];
          if (index.name === "" || index.name.length === 0) {
            return "Должно быть указано наименование показателя " + (i + 1) + "." + (j + 1)
          }
          if (index.id !== j * 100 + 100) {
            return "Идентификаторы показателей должны быть кратны 100 и следовать по порядку " +
                "(показатель " + (i + 1) + "." + (j + 1) + ")"
          }
          for (let k = 0; k < index.parameters.length; k++) {
            let parameter = index.parameters[k];
            if (parameter.name === "" || parameter.name.length === 0) {
              return "Должно быть указано наименование параметра " + (i + 1) + "." + (j + 1) + "." + (k + 1)
            }
            if (parameter.id !== k * 100 + 100) {
              return "Идентификаторы параметра должны быть кратны 100 и следовать по порядку " +
                  "(параметр " + (i + 1) + "." + (j + 1) + "." + (k + 1) + ")"
            }
          }
        }
      }
      return true
    },

    formConfig() {
      let config = {};
      if ("id" in this.config) {
        config.id = this.config.id;
      }
      if ("createdDate" in this.config) {
        config.createdDate = this.config.createdDate;
      }
      if ("modifiedDate" in this.config) {
        config.modifiedDate = this.config.modifiedDate;
      }
      if ("activateCounter" in this.config) {
        config.activateCounter = this.config.activateCounter;
      }
      if ("active" in this.config) {
        config.active = this.config.active;
      }
      if ("monitoring" in this.config) {
        config.monitoring = this.config.monitoring;
      }
      if ("staticFiles" in this.config) {
        config.staticFiles = this.config.staticFiles;
      }
      config.title = this.config.title;
      config.configJson = {
        type: this.config.configParams.type,
        title: this.config.title,
        shortTitle: this.config.configParams.shortTitle,
      }
      if ("description" in this.config.configParams) {
        config.configJson.description = this.config.configParams.description;
      } else {
        config.configJson.description = "";
      }
      if ("staticFiles" in this.config.configParams) {
        config.configJson.staticFiles = this.config.configParams.staticFiles;
      }
      if (this.config.configParams.type === "region") {
        config.configJson.regions = this.config.configParams.regions;
        config.configJson.czns = [];
      } else if (this.config.configParams.type === "czn") {
        config.configJson.czns = this.config.configParams.czns;
        config.configJson.regions = [];
      }
      config.configJson.sections = this.config.configParams.sections;
      // console.log('config.configJson', config.configJson)
      for (let i = 0; i < config.configJson.sections.length; i++) {
        config.configJson.sections[i].sectionName = "section" + (i + 1);
        for (let j = 0; j < config.configJson.sections[i].indices.length; j++) {
          config.configJson.sections[i].indices[j].indexName = "section" + (i + 1) + "_index" + (j + 1);
          for (let k = 0; k < config.configJson.sections[i].indices[j].parameters.length; k++) {
            config.configJson.sections[i].indices[j].parameters[k].parameterName = "section" + (i + 1) + "_index" + (j + 1) + "_param" + (k + 1);
            if (config.configJson.sections[i].indices[j].parameters[k].recommendedEndDate == null) {
              config.configJson.sections[i].indices[j].parameters[k].recommendedEndDate = "";
            }
          }
        }
      }
      // console.log('configFormed', config)
      return config
    },

    loadJson() {
      let file = this.$refs['fileInput'].files[0];
      let reader = new FileReader();
      reader.readAsText(file);
      reader.componentThis = this;
      reader.onload = function () {
        let sections
        try {
          sections = JSON.parse(reader.result)
        } catch (e) {
          reader.componentThis.errorText = "Файл должен содержать конфигурацию в формате JSON"
          return
        }
        let validStat = reader.componentThis.validateSection(sections.sections)
        if (validStat === true) {
          reader.componentThis.config.configParams.sections = sections.sections
        } else {
          reader.componentThis.errorText = validStat
        }
      };
      this.$refs['fileInput'].value = ""
      reader.onerror = function () {
        alert('Ошибка загрузки файла')
      };
    },

    selectAllRegions() {
      for (let region of this.regionList) {
        this.config.configParams.regions.push(region.code);
      }
      this.$forceUpdate();
    },

    selectAllCZN() {
      for (let czn of this.$cznDepartmentsList) {
        this.config.configParams.czns.push(czn.name);
      }
      for (let i = 0; i < this.$cznDepartmentsByRegionList.length; i++) {
        this.allCZNinRegionAreSelected[i] = true;
      }
      this.$forceUpdate();
    },

    deselectAllCZN() {
      this.config.configParams.czns = [];
      for (let i = 0; i < this.$cznDepartmentsByRegionList.length; i++) {
        this.allCZNinRegionAreSelected[i] = false;
      }
      this.$forceUpdate();
    },

    checkCZNinRegion(regionWithCZN, regionWithCZNIndex) {
      for(let czn of regionWithCZN.departments) {
        if (!this.config.configParams.czns.includes(czn.name)) {
          this.allCZNinRegionAreSelected[regionWithCZNIndex] = false;
          return
        }
      }
      this.allCZNinRegionAreSelected[regionWithCZNIndex] = true;
      this.$forceUpdate();
    },

    selectCZNinRegion(regionWithCZN, regionWithCZNIndex) {
      if (this.allCZNinRegionAreSelected[regionWithCZNIndex]) {
        //если выбраны все ЦЗН в регионе, то снимаем выделение со всех
        for (let czn of regionWithCZN.departments) {
          this.config.configParams.czns.splice(this.config.configParams.czns.indexOf(czn.name), 1)
        }
        this.allCZNinRegionAreSelected[regionWithCZNIndex] = false;
      } else {
        //если выбраны не все ЦЗН в регионе, то выделяем все
        for (let czn of regionWithCZN.departments) {
          if (!this.config.configParams.czns.includes(czn.name)) {
            this.config.configParams.czns.push(czn.name)
          }
        }
        this.allCZNinRegionAreSelected[regionWithCZNIndex] = true;
      }
      this.$forceUpdate();
    },

    truncateStr(str, n) {
      if (str.length > (n + 6)) {
        return str.substring(0, n - 3) + "..." + str.substring(str.length - 6, str.length)
      }
      return str
    },

    async deleteFile(staticFilePath) {
      let req = await api.delete(
          "/monitoring/typical/config/deleteStaticFile",
          {configId: this.id},
          staticFilePath);
      if (req.ok) {
        console.log('delete ok');
        await this.getUpdatedStaticFiles(this.id);
      } else {
        console.log('delete error');
      }
    },

    async getUpdatedStaticFiles() {
        let req = await this.$getApi("/monitoring/typical/config/getConfig", {id: this.id})
        if (req.ok) {
          // console.log('req.payload', req.payload)
          let config = req.payload;
          config.configParams = JSON.parse(config.configJson);
          this.config.configParams.staticFiles = config.configParams.staticFiles;
          this.staticFileKey += 1;
        } else {
          this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
        }
    },

    // async loadConfig(id) {
    //   let req = await this.$getApi("/monitoring/typical/config/getConfig", {id: id})
    //   // console.log('req', req)
    //   if (req.ok) {
    //     // console.log('req.payload', req.payload)
    //     this.config = req.payload
    //     this.config.configParams = JSON.parse(this.config.configJson)
    //     this.apiLoaded = true
    //   } else {
    //     this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
    //   }
    // },
  },

  filters: {
    dateFormat: function (date) {
      if (date == null) {
        return ''
      }
      let mdate = new Date(date)
      let options = {timeZone: 'Europe/Moscow', year: 'numeric', month: 'numeric', day: 'numeric'}
      return mdate.toLocaleDateString('ru-RU', options)
    }
  },

  async beforeMount() {
    this.id = this.$route.params.id;
    this.regionList = this.$cznDepartmentsByRegionList;
    if (this.id != null) {
      //режим редактирования
      await this.loadConfig(this.id);
      if (this.config.activateCounter !== 0 && this.config.activateCounter != null) { //нельзя редактировать конфиги, которые активировались хоть раз
        this.apiLoaded = false;
      }
      if (this.config.configParams.type === 'czn') { //для расставления галочек с именем региона в выборе цзн
        for (let i = 0; i < this.$cznDepartmentsByRegionList.length; i++) {
          let regionWithCZN = this.$cznDepartmentsByRegionList[i];
          this.checkCZNinRegion(regionWithCZN, i)
        }
      }
    } else {
      this.apiLoaded = true;
    }
  }
};
</script>

<style lang="scss">
@import "../../styles/main.scss";
</style>

<style scoped lang="scss">
.file-input {
  margin-top: 15px;
  .v-file-input {
    max-width: 50%;
  }
}
.file-input + .file-input {
  margin-top: 0;
}

.region-list-wrap {
  .region-buttons {
    display: flex;
    margin: 10px 0;
    .btn, button {
      margin-right: 8px;
	margin-left: 0;
      height: 30px;
    }
  }
  .region-list {
    max-height: 45vh;
    overflow-x: auto;
    .v-input--checkbox {
      margin: 0 0;
    }
  }
}

.file-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-bottom: 20px;

  .v-icon {
    margin-right: 10px;
    color: #0033a0;
  }
}

.list.config {
  margin-top: 8px;
}

.list-item-type {
  margin-right: 68px;
  tr {
    margin-top: -20px;
  }
  span {
    width: 100px;
    margin-left: 30px;
  }
  .v-input {
    width: calc(100% - 100px);
    flex-grow: 0;
    margin-left: 30px;
  }
  .v-select {
    margin-top: -20px;
    margin-bottom: -25px;
    width: 250px;
    .v-label {
      color: $cgray!important;
    }
  }

.v-input__append-inner {
    margin-top: 14px !important;
  }
}

.list-item.list-item-name {
    display: flex;
    align-items: center;
    .toggleSwitch {
      align-self: center;
    }

}

.list-item-type {
  .v-input--selection-controls {
    margin: 0 !important;
    padding: 0 !important;
  }
}

.v-expansion-panel .level2 {
  padding-left: 40px;
  padding-top: 0;
}

.edit, .delete {
  margin-left: -35px;
}

.file-delete {
  margin-left: 152px !important;
}

.v-expansion-panel-header {
  min-height: 50px !important;
  //padding: 4px 0 8px 0 !important;
}

.region-list .v-expansion-panel-header {
  border-bottom: 1px dashed #E8E8E9;
  &:last-child {
    border-bottom: none;
  }
}

.v-expansion-panel-header--active {
  min-height: 50px !important;
}

//.v-expansion-panel-content {
//  border-bottom: 1px dashed #E8E8E9;
  //&:last-child {
  //  border-bottom: none;
  //}
//}

.expansion-panel-checkbox {
  display: flex;
  align-items: start;
  justify-content: end;
}

</style>

