<template>
  <v-menu
      :close-on-content-click="true"
      :nudge-right="40"
      transition="scale-transition"
      max-width="290px"
      min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
          :label="labelName"
          readonly
          v-on="on"
          clearable
          @click:clear="clear()"
          :value= "dateValue | dateFormat"
          prepend-icon="mdi-calendar"
      >
        <v-icon color="#3577BE"></v-icon>
      </v-text-field>
    </template>
    <v-date-picker
        no-title
        scrollable
        locale="ru-RU"
        v-model="dateValueLocal"
        @change="fieldChanged"
    >
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: "DateField",
  props: ['dateValue', 'labelName'],
  data() {
    return {
      dateValueLocal: this.dateValue
    }
  },
  filters: {
    dateFormat: function (date) {
      if (date == null || date === '') {
        return ''
      }
      let mdate = new Date(date)
      let options = {timeZone: 'Europe/Moscow', year: 'numeric', month: 'numeric', day: 'numeric'}
      return mdate.toLocaleDateString('ru-RU', options)
    },
  },
  methods: {

    fieldChanged(value) {
      this.$emit('minput', value)
      this.$forceUpdate()
    },

    clear() {
      this.$emit('minput', null)
      this.$forceUpdate()
    }
  }
}
</script>

<style scoped lang="scss">
.v-input__append-inner {
  margin-top: 14px !important;
}
</style>